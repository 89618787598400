import text from '../../assets/translation/translation.json'

function translation() {

    let lang = window.location.href.split('/')[3]
    lang = lang.split("?")[0];

    if (lang === "pl" || lang === "en") {
        return text[lang]
    } else {
        return text["pl"]
    }
}

export default translation()
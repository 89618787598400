import React, { useEffect, useState } from "react";
import Footer from "../Footer/footer";
import { Link, useParams } from "react-router-dom";
import ArticleGallery from "../../Components/ArticleGallery/articleGallery";
import { exportDay, exportMonth, exportMonthShort, exportYear } from "../../Components/Helpers/functions";
import { ArrowLeft, Facebook, Linkedin, Twitter } from "../../assets/images/svg/svg";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import styles from "./article.module.scss";
import SeeAlsoSlider from "../../Components/SeeAlsoSlider/seeAlsoSlider";
import { getArticle, getArticle2, getComments, getPostImages } from "../../assets/api/apiCall";
import { Loader } from "../../Components/Loader/loader";
import { authorUrl, only10Words, postUrl, removeHtml } from "../../Components/Helpers/myUrlHelper";
import CommentForm from "../../Components/CommentForm/commentForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";
import translation from "../../Components/Helpers/translation";

function Article() {
    const size = useWindowSize();
    const [openGallery, setOpenGallery] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [startSlider, setStartSlider] = useState(0);
    const [myTimeOutArray, setMyTimeOutArray] = useState([]);
    const [data, setData] = useState(null);
    const [comments, setComments] = useState(null);
    const [images, setImages] = useState(null);
    const [dataSlider, setDataSlider] = useState(null);

    let { lang, id } = useParams();
    let history = useHistory();
    id = parseInt(id);

    function openModalComment() {
        myTimeOutArray.forEach((item) => {
            clearTimeout(item);
        });
        setShowModal(true);
        setTimeout(() => {
            setIsModalOpened(true);
        }, 10);
    }

    useEffect(() => {
        const documentWidth = document.documentElement.clientWidth;
        const windowWidth = window.innerWidth;
        const scrollBarWidth = windowWidth - documentWidth;
        const body = document.getElementsByTagName("body")[0];

        if (openGallery) {
            body.classList.add(styles.bodyOverflow);
            body.style.paddingRight = `${scrollBarWidth}px`;
        } else {
            body.classList.remove(styles.bodyOverflow);
            body.style.paddingRight = `unset`;
        }
    }, [openGallery, size.width]);

    useEffect(() => {
        if (!data) {
            getArticle(lang, id).then((json) => {
                if (json) {
                    const url = window.location;
                    if (url.pathname.split("/")[3] != postUrl(json)) {
                        window.history.replaceState({}, null, window.location.origin + "/" + lang + "/artykul/" + postUrl(json));
                    }

                    setData(json);
                } else {
                    window.history.replaceState({}, null, window.location.origin + "/" + lang);
                    document.location.reload();
                }
            });
            setTimeout(() => {
                getPostImages(id).then((json) => {
                    setImages(json);
                });
            }, 30);

            setTimeout(() => {
                getComments(id).then((json) => {
                    setComments(json);
                });
            }, 60);

            if (!dataSlider) {
                setTimeout(() => {
                    getArticle2(`?lang=${lang}&id=${id}&slider=true`).then((json) => {
                        setDataSlider(json);
                    });
                }, 90);
            }
        }
    }, [id]);

    const share = (type) => {
        let popUpObj;
        if (type === 1) {
            popUpObj = window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, "ModalPopUp", "width=600, height=600");
        } else if (type === 2) {
            popUpObj = window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`, "ModalPopUp", "width=600, height=600");
        } else if (type === 3) {
            popUpObj = window.open(`https://www.linkedin.com/cws/share?url=${window.location.href}`, "ModalPopUp", "width=600, height=600");
        }
        popUpObj.focus();
    };

    return (
        <>
            <Loader data={data} />
            {data ? (
                <>
                    <Helmet>
                        <title>{`Nifc Blog - ${data.title}`}</title>
                        <meta name="description" content={only10Words(removeHtml(data.lead))} />
                    </Helmet>
                    <div className={styles.articleWrapper}>
                        <div className={styles.articleContainer}>
                            <div className={styles.articleSections}>
                                <div className={styles.leftSection}>
                                    <div className={styles.left}>
                                        <button type={"button"} className={styles.goBack} onClick={() => history.goBack()}>
                                            <ArrowLeft />
                                        </button>
                                        {size.width <= 800 ? (
                                            <div className={styles.share}>
                                                <button type={"button"} className={styles.shareButton}>
                                                    <Facebook />
                                                </button>
                                                <button type={"button"} className={styles.shareButton}>
                                                    <Twitter />
                                                </button>
                                                <button type={"button"} className={styles.shareButton}>
                                                    <Linkedin />
                                                </button>
                                            </div>
                                        ) : null}
                                    </div>
                                    {data ? (
                                        <div className={styles.date}>
                                            <p className={styles.year}>{exportYear(data.created_at)}</p>
                                            <p className={styles.day}>{exportDay(data.created_at)}</p>
                                            <p className={styles.month}>{exportMonth(data.created_at)}</p>
                                        </div>
                                    ) : null}
                                    {size.width > 800 ? (
                                        <div className={styles.share}>
                                            <button type={"button"} className={styles.shareButton}>
                                                <Facebook />
                                            </button>
                                            <button type={"button"} className={styles.shareButton}>
                                                <Twitter />
                                            </button>
                                            <button type={"button"} className={styles.shareButton}>
                                                <Linkedin />
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                <div className={styles.rightSection}>
                                    <img src={data ? data.post_img : null} alt={""} className={styles.mainImage} />
                                    <a href={`/${lang}?categories=${data?.category_id}&page=1`} type={"button"} className={styles.category}>
                                        {data?.category_name}
                                    </a>
                                    {data ? (
                                        <>
                                            <h1 className={styles.title}>{data.title}</h1>
                                            <div className={styles.content} dangerouslySetInnerHTML={{ __html: data.content }} />
                                            <div className={styles.contentBottom} dangerouslySetInnerHTML={{ __html: data.lead }} />
                                        </>
                                    ) : null}
                                    <div className={styles.contentFooter}>
                                        <div className={styles.authorContainer}>
                                            {/* {data?.author.map((item, index) => {
                                                return (
                                                    <a key={index} href={`/${lang}/autor/${authorUrl(item)}`} className={styles.author}>
                                                        <div className={styles.authorPhoto} style={{ backgroundImage: `url("${item.img_icon}")` }} />
                                                        <div className={styles.textWrapper}>
                                                            <p className={styles.authorTitle}>Autor</p>
                                                            <p className={styles.authorName}>{item.name}</p>
                                                        </div>
                                                    </a>
                                                );
                                            })} */}
                                            {data?.author && (
                                                <a href={`/${lang}/autors/${authorUrl(data.author)}`} className={styles.author}>
                                                    <div className={styles.authorPhoto} style={{ backgroundImage: `url("${data.author.img_icon}")` }} />
                                                    <div className={styles.textWrapper}>
                                                        <p className={styles.authorTitle}>{translation.site.article.author}</p>
                                                        <p className={styles.authorName}>{data.author.name}</p>
                                                    </div>
                                                </a>
                                            )}
                                        </div>

                                        <div className={styles.shareContainer}>
                                            <p className={styles.text}>{translation.site.article.share}</p>
                                            <div className={styles.shareWrapper}>
                                                <button type={"button"} onClick={() => share(1)} className={styles.shareButton}>
                                                    <Facebook />
                                                </button>
                                                <button type={"button"} onClick={() => share(2)} className={styles.shareButton}>
                                                    <Twitter />
                                                </button>
                                                <button type={"button"} onClick={() => share(3)} className={styles.shareButton}>
                                                    <Linkedin />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {images?.length > 0 ? (
                                        <div className={styles.galleryWrapper}>
                                            <h2 className={styles.galleryTitle}>{translation.site.article.gallery}</h2>
                                            <div className={styles.itemsWrapper}>
                                                {images.map((item, index) => {
                                                    return (
                                                        <button
                                                            type={"button"}
                                                            className={styles.item}
                                                            key={index}
                                                            onClick={() => {
                                                                setOpenGallery(true);
                                                                setStartSlider(index);
                                                            }}
                                                        >
                                                            <div className={styles.image} style={{ backgroundImage: `url("${item.file_name}")` }} />
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : null}
                                    {/* <div className={styles.commentWrapper}>
                                        <div className={styles.titleContainer}>
                                            <div className={styles.title}>komentarze</div>
                                            <button className={styles.btn} onClick={() => openModalComment()}>
                                                skomentuj
                                            </button>
                                        </div>
                                        <div className={styles.listContainer}>
                                            {comments?.map((item, index) => {
                                                return (
                                                    <div key={index} className={styles.commentElement}>
                                                        <div className={`${styles.column} ${styles.columnLeft}`}>
                                                            <a href={`/${lang}/autor/${authorUrl(item.author)}`} className={styles.imgAuthor} style={{ backgroundImage: `url("${item.author.img_icon}")` }}></a>
                                                        </div>
                                                        <div className={`${styles.column} ${styles.columnRight}`}>
                                                            <p className={styles.textBig}>{item.author.name}</p>
                                                            <p className={styles.textSmall}>
                                                                {exportDay(item.created_at)} {exportMonthShort(item.created_at, lang)} {exportYear(item.created_at)}
                                                            </p>
                                                            <p className={styles.textNormal}>{item.text}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            {dataSlider?.length > 0 ? (
                                <div className={styles.seeAlso}>
                                    <h2 className={styles.seeAlsoTitle}>{translation.site.article.seeAlso}</h2>
                                    <div className={styles.line} />
                                    <SeeAlsoSlider items={dataSlider} />
                                </div>
                            ) : null}
                        </div>
                        {images?.length > 0 ? <ArticleGallery opened={openGallery} setOpened={setOpenGallery} images={images} startSlider={startSlider} setStartSlider={setStartSlider} /> : null}
                        {showModal ? <CommentForm isModalOpened={isModalOpened} setIsModalOpened={setIsModalOpened} setShow={setShowModal} myTimeOutArray={myTimeOutArray} setMyTimeOutArray={setMyTimeOutArray} /> : null}
                        <Footer />
                    </div>
                </>
            ) : null}
        </>
    );
}

export default Article;

import { apiUrls } from "./apiURL";

export async function getCategories(lang = "pl") {
    const getUrl = `${apiUrls.mainURL}${apiUrls.category}?lang=${lang}`;

    const options = {
        method: "GET",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function getArticle(lang = "pl", id = null, categories = []) {
    let getUrl = `${apiUrls.mainURL}${apiUrls.posts}?lang=${lang}`;
    if (categories.length > 0) getUrl += `&categories=${categories}`;
    if (id) getUrl += `&id=${id}`;

    const options = {
        method: "GET",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function getArticle2(queryStrin) {
    let getUrl = `${apiUrls.mainURL}${apiUrls.posts}${queryStrin}`;

    const options = {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        cache: "no-cache",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function getPostImages(id) {
    const getUrl = `${apiUrls.mainURL}${apiUrls.posts}/images?id=${id}`;

    const options = {
        method: "GET",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function getAuthors(lang = "pl") {
    const getUrl = `${apiUrls.mainURL}${apiUrls.author}?lang=${lang}`;

    const options = {
        method: "GET",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function getAuthor(lang = "pl", id) {
    const getUrl = `${apiUrls.mainURL}${apiUrls.author}?lang=${lang}&id=${id}`;

    const options = {
        method: "GET",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function getAuthorPosts(lang = "pl", id, page, pagination = true) {
    let getUrl = `${apiUrls.mainURL}${apiUrls.author}/posts/?lang=${lang}&id=${id}`;
    if (page) getUrl += `&page=${page}`;
    if (pagination == false) getUrl += "&pagination=false";

    const options = {
        method: "GET",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function getComments(id, page, pagination = true) {
    let getUrl = `${apiUrls.mainURL}comments?id=${id}`;
    if (page) getUrl += `&page=${page}`;
    if (pagination == false) getUrl += "&pagination=false";

    const options = {
        method: "GET",
    };
    const response = await fetch(getUrl, options);
    const end = await response.json();
    return end;
}

export async function addComment(json) {
    let postUrl = `${apiUrls.mainURL}comments/add`;

    const options = {
        method: "POST",
        headers: {
            // 'Accept': 'application/json',
            "Content-Type": "application/json",
        },
        // mode: 'no-cors',
        // cache: 'no-cache',
        // credentials: 'include',
        body: JSON.stringify(json),
    };

    const response = await fetch(postUrl, options);
    const end = await response.json();
    return end;
}

import React, {useEffect, useRef} from "react";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import '@splidejs/react-splide/css/core';

import styles from './articleGallery.module.css'
import {ArrowGallery} from "../../assets/images/svg/svg";

function ArticleGallery(props) {

    const modalContainerRef = useRef();
    const modalRef = useRef();
    const closeRef = useRef();
    const sliderRef = useRef();
    const sliderRefThumb = useRef();

    const hideModal = (event) => {
        if(event.target === modalRef.current || event.target === closeRef.current || event.target === modalContainerRef.current) {
            if(props.index !== undefined) {
                props.setOpened(false, props.index)
            } else {
                props.setOpened(false)
            }
        }
    }

    useEffect(() => {
        sliderRef.current.go(props.startSlider)
    },[props.startSlider])

    useEffect(() => {
        if ( sliderRef.current && sliderRefThumb.current && sliderRefThumb.current.splide ) {
            sliderRef.current.sync(sliderRefThumb.current.splide);
        }
    },[])

    return (
        <div ref={modalRef} className={`${styles.modalWrapper} ${!props.opened ? styles.modalWrapperHidden : ''}`} onClick={hideModal}>
            <div ref={modalContainerRef} className={styles.modalContainer}>
                <div className={styles.modal}>
                    <div ref={closeRef} className={styles.close} onClick={hideModal}/>
                    <Splide hasTrack={ false } aria-label="..."
                            ref={sliderRef}
                            className={styles.carousel}
                            data-slided={false}
                            options={{
                                type: 'fade',
                                autoplay: false,
                                interval: 3000,
                                pagination: false,
                                drag: true,
                                speed: 350,
                                easing: 'ease-in-out',
                                updateOnMove: true,
                                lazyLoad: "nearby",
                                start : props.startSlider
                            }}
                    >
                        <div className={`splide__arrows ${styles.pagination}`}>
                            <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.leftArrow}`}>
                                <ArrowGallery/>
                            </button>
                            <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.rightArrow}`}>
                                <ArrowGallery/>
                            </button>
                        </div>
                        <SplideTrack className={styles.track}>
                            {props.images.map((item, index) => {
                                return (
                                    <SplideSlide key={index} className={styles.slide}>
                                        <img
                                            data-splide-lazy={item.file_name}
                                            alt={item.alt}
                                        />
                                        {/* <p className={styles.desc} dangerouslySetInnerHTML={{__html: item.desc}}/> */}
                                    </SplideSlide>
                                )
                            })}
                        </SplideTrack>
                    </Splide>
                    <Splide hasTrack={ false } aria-label="..."
                            ref={sliderRefThumb}
                            className={styles.carouselThumb}
                            data-slided={false}
                            options={{
                                type: 'slide',
                                autoplay: false,
                                interval: 3000,
                                pagination: false,
                                drag: true,
                                speed: 350,
                                easing: 'ease-in-out',
                                updateOnMove: true,
                                lazyLoad: "sequential",
                                start : props.startSlider,
                                fixedWidth : '100px',
                                gap: 10,
                                arrows: false,
                                isNavigation: true,
                            }}
                    >
                        <SplideTrack className={styles.track}>
                            {props.images.map((item, index) => {
                                return (
                                    <SplideSlide key={index} className={styles.slide}>
                                        <img
                                            data-splide-lazy={item.file_name}
                                            // alt={item.alt}
                                        />
                                    </SplideSlide>
                                )
                            })}
                        </SplideTrack>
                    </Splide>
                </div>
            </div>
        </div>
    )
}

export default ArticleGallery
import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import InputCheckout from "../../Components/Inputs/inputCheckout";
import InputText from "../../Components/Inputs/inputText";
import SocialIcon from "../../Components/SocialIcons/socialIcon";
import styles from "./footer.module.css";
import scrollToElement from "./../../Components/Helpers/scrollTo";
import { apiUrls } from "./../../assets/api/apiURL";

import translation from "../../Components/Helpers/translation";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import { orphans } from "../../Components/Helpers/functions";
import { useParams } from "react-router-dom/cjs/react-router-dom";

function Footer() {
    const size = useWindowSize();
    const messageRef = useRef();
    const [showLoader, setShowLoader] = useState(false);
    const { lang } = useParams();

    const validateNewsletterForm = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = translation.site.footer.inputEmail.errors[0];
            // console.log("Podaj mail");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = translation.site.footer.inputEmail.errors[1];
            // console.log("zły mail");
        }

        if (!values.agreement) {
            errors.agreement = translation.site.footer.inputEmail.agreement.errors[0];
            // console.log("Zaznacz zgode");
        }

        return errors;
    };

    const formikNewsletterForm = useFormik({
        initialValues: {
            email: "",
            agreement: false,
        },
        validateOnChange: false,
        validate: validateNewsletterForm,
        onSubmit: (values, actions) => {
            // console.log(values)
            setShowLoader(true);

            const formData = new FormData();

            formData.append("email", values["email"]);
            formData.append("agreement", values['agreement'] ? 1 : 0);

            fetch(`${apiUrls.mainURL}${apiUrls.newsletter}`, {

                method: "POST",
                body: formData,
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json) {
                        messageRef.current.innerText = translation.site.footer.inputEmail.success;
                    }
                });
        },
    });

    useEffect(() => {
        orphans();
    }, []);

    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footerContainer}>
                <h2 className={styles.title}>{translation.site.footer.title}</h2>
                <h3 className={styles.subTitle}>{translation.site.footer.subTitle}</h3>
                <p className={styles.message} ref={messageRef}></p>
                <form onSubmit={formikNewsletterForm.handleSubmit} className={styles.formContainer}>
                    <div className={styles.errorText}>{formikNewsletterForm.errors["email"]}</div>

                    <InputText inputName={"email"} formikData={formikNewsletterForm} textData={translation.site.footer.inputEmail} /> 
                    <label className={styles.label}>
                        <InputCheckout inputName={"agreement"} formikData={formikNewsletterForm} />
                        <span className={styles.labelText}>
                            {translation.site.footer.checkboxLabel}
                            <Link to={`/${lang}`} className={styles.link}>
                                {translation.site.footer.linkText}
                            </Link>
                        </span>
                    </label>
                    <div className={styles.errorText}>
                        <span>{formikNewsletterForm.errors["agreement"]}</span>
                    </div>
                    <button type={"submit"} className={styles.submitButton} onChange={formikNewsletterForm.handleChange}>
                        {translation.site.footer.submitButton}
                    </button>
                </form>
                <div className={styles.footerBottom}>
                    <div className={styles.left}>
                        <a href={translation.site.footer.bottom.left.nifcLink}>
                            <div className={styles.logoBottom} />
                        </a>
                        <Link to={`/${lang}`} className={styles.bottomTitle}>
                            {translation.site.footer.bottom.left.title}
                        </Link>
                    </div>
                    {size.width <= 700 ? (
                        <div className={styles.socials}>
                            <SocialIcon type={"facebook"} borders={true} />
                            <SocialIcon type={"youtube"} borders={true} />
                            <SocialIcon type={"twitter"} borders={true} />
                        </div>
                    ) : null}
                    <div className={styles.right}>
                        <div className={styles.content}>
                            <a href={`https://storage.nifc.pl/web_files/_files/Ochrona_danych_osobowych.pdf`} className={styles.linkSite}>
                                {translation.site.footer.bottom.right.privacyPolicy}
                            </a>
                            {size.width > 700 ? (
                                <div className={styles.socials}>
                                    <SocialIcon type={"facebook"} borders={true} />
                                    <SocialIcon type={"youtube"} borders={true} />
                                    <SocialIcon type={"twitter"} borders={true} />
                                </div>
                            ) : null}
                            <a href={`https://nifc.pl/${lang}`} className={styles.link}>
                                {translation.site.footer.bottom.right.nifcPl}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;

import slug from "slug";

export function authorUrl(author) {
    if (author)
        return `${author.id}-${author.name ? slug(author.name) : ''}`;
    return null;
}

export function postUrl(post) {
    return `${post.id}-${post.title ? slug(post.title) : ''}`;
}

export function removeHtml(value) {
    value = value.replace("<p>", "")
    value = value.replace("</p>", "")
    return value
}

export function only10Words(value) {
    return value.split(/\s+/).slice(0, 10).join(" ");
}
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import "../../assets/styles/index.css";
import { Loader } from "../../Components/Loader/loader";
import Article from "../Article/article";
import Authors from "../Authors/authors";
import Author from "../Author/author";
import Header from "../Header/header";
import MainPage from "../MainPage/mainPage";
import scrollTo from "../../Components/Helpers/scrollTo";
import { getCategories } from "../../assets/api/apiCall";

const App = () => {
    const [categories, setCategories] = useState(null);
    const openSortRef = useRef(null);
    let lang = window.location.href.split("/")[3];
    lang = lang.split("?")[0];
    if (lang != "pl" && lang != "en") lang = "pl";

    useEffect(() => {
        if (!categories) {
            const urlParams = new URLSearchParams(window.location.search);
            let readCategories = urlParams.get("categories");
            getCategories(lang).then((json) => {
                setCategories(json);

                if (readCategories) {
                    const topEl = document.getElementById("mainListTop");
                    if (topEl) scrollTo(topEl.clientHeight, 500);
                    json.forEach((element) => {
                        if (element.id == readCategories) openSortRef.current.textContent = element.name;
                    });
                }
            });
        }
    }, []);

    return (
        // <Suspense fallback={<Loader builder={true}/>}>
        <Switch>
            <Route exact path={"/:lang(pl|en)"}>
                <Header white={true} categories={categories} />
                <MainPage categories={categories} openSortRef={openSortRef} />
            </Route>

            <Route exact path={"/:lang(pl|en)/artykul/:id"}>
                <Header underline={true} changeColor={true} categories={categories} />
                <Article />
            </Route>

            <Route exact path={"/:lang(pl|en)/autorzy"}>
                <Header underline={true} changeColor={true} categories={categories} />
                <Authors />
            </Route>

            <Route exact path={"/:lang(pl|en)/autors/:id"}>
                <Header underline={true} changeColor={true} categories={categories} />
                <Author />
            </Route>

            <Route exact path={`*`}>
                {lang === "pl" || lang === "en" ? <Redirect to={`/pl`} /> : <Redirect to={"/pl"} />}
                {/* <Redirect to="/" /> */}
            </Route>
        </Switch>
        // </Suspense>
    );
};

function Builder() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default Builder;

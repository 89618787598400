import React, { useEffect, useState } from "react";
import Footer from "../Footer/footer";

import styles from "./authors.module.scss";

import authorImageMockup from "../../assets/images/mockup/authorMockup.jpg";
import { Link, useParams } from "react-router-dom";
import { getAuthors } from "../../assets/api/apiCall";
import translation from "../../Components/Helpers/translation";
import { Loader } from "../../Components/Loader/loader";
import { authorUrl, only10Words } from "../../Components/Helpers/myUrlHelper";
import { Helmet } from "react-helmet";

function Authors() {
    const [data, setData] = useState();
    let { lang } = useParams();

    useEffect(() => {
        getAuthors().then((json) => {
            setData(json);
        });
    }, []);

    return (
        <>
            <Loader data={data} />
            {data ? (
                <>
                    <Helmet>
                        <title>{`Nifc Blog - ${lang == "en" ? "List of authors" : "Lista autorów "}`}</title>
                        <meta name="description" content={lang == "en" ? "The Nifc blog is the largest collection of all content related to the Romantic era" : "Blog Nifc to największy zbiór wszystkich treści połączonych z epoką romantyzmu"} />
                    </Helmet>
                    <div className={styles.authorsWrapper}>
                        <div className={styles.authorsContainer}>
                            {data?.map((item, index) => {
                                return (
                                    <div className={styles.author} key={index}>
                                        <Link to={`/${lang}/autors/${authorUrl(item)}`} className={styles.left}>
                                            <div className={styles.image} style={{ backgroundImage: `url("${item.img_icon}")` }} />
                                            <h2 className={styles.name}>{item.name}</h2>
                                        </Link>
                                        {/* <div className={styles.right}>
                                            <div className={styles.top}>
                                                <p className={styles.title}>{translation.site.authors.label[0]}</p>
                                                <p className={styles.counter}>{item.posts.length}</p>
                                            </div>
                                            <div className={styles.middle}>
                                                {item.posts.map((post, postIndex) => {
                                                    return (
                                                        <div className={styles.articleWrapper} key={postIndex}>
                                                            <Link className={styles.articleLink} to={`/${lang}/artykul/${post.id}-${post.title}`}><span className={styles.linkText}>{post.title}</span></Link>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <Link className={styles.seeMore} to={`/${lang}/autor/${item.id}`}>{translation.site.authors.label[1]}</Link>
                                        </div> */}
                                    </div>
                                );
                            })}
                        </div>
                        <Footer />
                    </div>
                </>
            ) : null}
        </>
    );
}

export default Authors;

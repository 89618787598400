import React from "react";

import styles from "./inputs.module.css";

function InputText(props) {
    return (
        <label className={styles.label}>
            <input className={`${styles.input} ${props.formikData.errors.email ? styles.error : ""}`}
                    name={props.inputName} 
                    type={"text"} 
                    onChange={props.formikData.handleChange} 
                    onBlur={props.formikData.handleBlur} 
                    value={props.formikData.values[props.inputName]} 
                    placeholder={`${props.textData.placeholder !== "" ? props.textData.placeholder : ""}`} />
        </label>
    );
}

export default InputText;

import React, { useRef } from "react";

import styles from './modal.module.scss'

function Modal(props) {

    const modalBackgroundRef = useRef();
    const modalRef = useRef();
    const closeRef = useRef()

    const hideModal = (event) => {
        if (event.target === modalRef.current || event.target === closeRef.current) {
            // props.setOpened(!props.opened)
            props.setOpened(false)
            if (props.setShow) {
                props.myTimeOutArray.forEach((item) => {
                    clearTimeout(item);
                })
                const myTime = setTimeout(() => { props.setShow(false); }, 1000)
                props.setMyTimeOutArray([myTime])
            }
        }
    }

    return (
        <>
            <div ref={modalBackgroundRef} className={`${props.isModalInModal ? styles.modalBackgroundMoved : styles.modalBackground} ${!props.opened ? styles.modalBackgroundHidden : ''}`} data-opened={props.opened} />
            <div ref={modalRef} className={`${styles.modalWrapper} ${!props.opened ? styles.modalWrapperHidden : ''}`} data-opened={props.opened} onClick={hideModal}>
                <div className={styles.modalContainer}>
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default Modal
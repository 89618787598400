import React from "react";
import styles from "./seeAlsoSlider.module.css";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import '@splidejs/react-splide/css/core';
import {Link} from "react-router-dom";
import {ArrowGallery} from "../../assets/images/svg/svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { postUrl } from "../Helpers/myUrlHelper";

function SeeAlsoSlider(props) {
    let { lang } = useParams();
    
    let myDrag = true;
    if(props.items.length <= 2){
        myDrag = false;
    }

    return (
        <Splide
            hasTrack={ false }
            aria-label="..."
            className={styles.carousel}
            data-slided={false}
            options={{
                type: 'slide',
                autoplay: false,
                interval: 3000,
                pagination: false,
                drag: myDrag,
                speed: 350,
                easing: 'ease-in-out',
                updateOnMove: true,
                perPage: 2,
                perMove: 2,
                gap: 10,
                breakpoints : {
                    800 : {
                        fixedWidth: '338px',
                        drag: true,
                        perPage: 1,
                        perMove: 1
                    },
                    500 : {
                        fixedWidth: '318px',
                        gap: 10
                    },
                    400 : {
                        fixedWidth: '80vw',
                        gap: 10
                    }
                }
            }}
        >
            <div className={`splide__arrows`}>
                <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.leftArrow}`}>
                    <ArrowGallery/>
                </button>
                <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.rightArrow}`}>
                    <ArrowGallery/>
                </button>
            </div>
            <SplideTrack className={styles.track}>
                {props.items.map((item, index) => {
                    return (
                        <SplideSlide key={index} className={styles.slide}>
                            <a href={`/${lang}/artykul/${postUrl(item)}`} className={styles.link}>
                                <div className={styles.imageWrapper}>
                                    <div className={styles.image} style={{backgroundImage: `url("${item.post_img}")`}}/>
                                </div>
                                <h3 className={styles.title}>{item.title}</h3>
                            </a>
                        </SplideSlide>
                    )
                })}
            </SplideTrack>
        </Splide>
    )
}

export default SeeAlsoSlider
import React from "react";

import styles from "./inputs.module.css";

function InputCheckout(props) {

    return (
        <input
            className={`${styles.checkbox} ${props.formikData.errors.agreement ? styles.agreement : ""}`}
            type={"checkbox"}
            name={props.inputName} 
            checked={props.formikData.values[props.inputName]}  
            onChange={props.formikData.handleChange} 
        />
    )
}

export default InputCheckout




import React from "react";

import styles from "./socialIcon.module.scss";

function SocialIcon(props) {
    const typeToHref = {
        facebook: "https://www.facebook.com/profile.php?id=100069819969917",
        youtube: "https://www.youtube.com/channel/UCSTXol20Q01Uj-U5Yp3IqFg",
        twitter: "https://twitter.com/chopininstitute",
    };

    const href = typeToHref[props.type] || "/";

    return (
        <a href={href} className={`${styles.socialWrapper} ${props.borders ? styles.socialWrapperBorders : ""}`}>
            {props.type === "facebook" ? <div className={`${styles.social} ${styles.facebook}`} /> : null}
            {props.type === "youtube" ? <div className={`${styles.social} ${styles.youTube}`} /> : null}
            {props.type === "twitter" ? <div className={`${styles.social} ${styles.twitter}`} /> : null}
        </a>
    );
}

export default SocialIcon;
